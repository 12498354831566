<template>
  <main class="superCenter">
    <picture class="logo">
      <source
        media="(min-aspect-ratio: 10/10)"
        srcset="../assets/images/logo/ToDustLoggaSand.png"
      />
      <source srcset="../assets/images/logo/ToDustSigillSand.png" />
      <img
        class="logo"
        src="../assets/images/logo/ToDustLoggaSand.png"
        alt="To Dust Logga"
      />
    </picture>

    <div id="lang">
      <router-link
        v-for="language in languages"
        :key="language"
        :to="{ name: 'Home', params: { locale: language } }"
      >
        <img
          class="btnLang"
          :src="getImagePath(language)"
          :alt="`${$t('view.index.language-option')}: ${language}`"
        />
      </router-link>
    </div>
    <Copyright />
  </main>
</template>

<script>
import Copyright from '@/components/Copyright.vue';

export default {
  name: 'Index',
  components: {
    Copyright,
  },
  methods: {
    getImagePath(language) {
      return require(`../assets/images/icons/language-icons/ikon-${language}.png`);
    },
  },
  data() {
    return {
      languages: process.env.VUE_APP_I18N_SUPPORTED_LOCALES.split(','),
    };
  },
};
</script>

<style scoped>
/**************************
Skapare: Fredrik Wendel
Datum: 2020-08-18
Senast uppdaterad: E/T
**************************/
/* Detta stilblad används till To Dust-hemsidans indexsida */

/* Detta attribut får alla "main"s element att centreras */
.superCenter {
  /* Positionen måste sättas relativ om marginal från toppen
      ska fungera. Sedermera används attributet "top" istället för
      "margin-top" för att ange hur stort avstånd från toppen objektet
      ska placeras. Detta för att undvika artefakter som gör så att
      höjdmarginalen ökar när sidan blir bredare och att sidan "skjuts"
      neråt vilket gör att en scroll-bar skapas. */
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

/* Dessa css regler förhindrar att delar av sidan hamnar utanför skärmen
och blir otillgängliga i mobilt läge */
@media (max-width: 600px) and (max-height: 350px) {
  .superCenter {
    top: 0;
    transform: translateY(0);
  }
}

/* Dessa css regler förhindrar att delar av sidan hamnar utanför skärmen
och blir otillgängliga i skrivbords läge */
@media (min-aspect-ratio: 10/4) {
  .superCenter {
    top: 0;
    transform: translateY(0);
  }
}

/*Lotypens ID*/
.logo {
  /* Maximal bredd minimerar risken för en horizontell scroll-bar
    på sidan p.g.a. logon. Maximal höjd minimerar risken att för att
    del av logon hamnar utanför skärmen då logon kommer att skala om
    sig om höjden blir för låg. */
  max-width: 90%;
  /* Max height funkar enbart när elementet inte är i en div tagg */
  max-height: 80%;
}

.btnLang {
  margin-top: 5%;
  margin-left: 1%;
  margin-right: 1%;
  max-width: 25%;
  max-height: 25%;
}

/* Ändrar avståndet mellan knapparna för att mer passa till sigillet */
@media (min-width: 600px) {
  .btnLang {
    max-width: 15%;
    max-height: 15%;
  }
}

/* För denna subklassen sätts textdekoration till "none". Om detta inte görs
Kan en artefakt uppstå där det blir ett litet "länk"-sträck bredvid varje
länk.*/
a {
  text-decoration: none;
  color: #e4e1d4;
}
</style>
